import type { Geoobject } from '@/types/main'

export const getMyPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Геолокация не поддеживается вашим браузером'))
    }

    navigator.geolocation.getCurrentPosition(resolve, reject)
  })
}

async function reverseGeocoding(
  dependencies: any,
  city: string,
  query: number[],
): Promise<Geoobject> {
  const { axios } = dependencies

  // @ts-ignore
  const { data } = await axios.get<Geoobject>(`${city}/geo/address/`, {
    params: {
      ll: query.toString(),
    },
  })

  return {
    address: data.address,
    coordinates: query,
  }
}

async function directGeocoding(
  dependencies: any,
  city: string,
  query: string,
): Promise<Geoobject> {
  const { axios } = dependencies
  const { data } = await axios.get<Geoobject>(`${city}/geo/coordinates/`, {
    params: {
      address: query,
    },
  })

  return {
    address: data.address,
    coordinates: data.coordinates,
  }
}

export function getGeocodeService(dependencies: any, city: string) {
  const { axios } = dependencies

  function geocode(query: number[] | string): Promise<Geoobject> {
    switch (typeof query) {
      case 'string':
        return directGeocoding({ axios }, city, query)
      case 'object':
        return reverseGeocoding({ axios }, city, query)
      default:
        throw new TypeError(`Неправильный формат запроса ${query}`)
    }
  }

  return {
    geocode,
  }
}
