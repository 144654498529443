<script setup lang="ts">
import type { PropType } from 'vue'

import CyrillicToTranslit from 'cyrillic-to-translit-js'
import ruLayoutConvert from 'convert-layout'

/* Types */
import type { FormattedCity } from '@/types/api/cities'

const props = defineProps({
  activeSearch: {
    type: Boolean,
    required: true,
    default: false,
  },
  searchQuery: {
    type: String,
    required: true,
    default: '',
  },
  formattedCitiesList: {
    type: Array as PropType<FormattedCity[]>,
    required: true,
  },
})

const cityTitle = 'Перейти на сайт Farfor в городе '

const highlightMatchedLetters = (cityName: string) => {
  // находим и выделяем совпадающие буквы
  if (!props.searchQuery) return cityName

  const name: string = cityName.toLowerCase()
  let searchQueryString: string

  if (name.includes(props.searchQuery)) {
    searchQueryString = props.searchQuery
  } else if (name.includes(ruLayoutConvert.ru.fromEn(props.searchQuery))) {
    searchQueryString = ruLayoutConvert.ru.fromEn(props.searchQuery)
  } else {
    searchQueryString = CyrillicToTranslit().reverse(props.searchQuery)
  }

  return cityName.replace(
    new RegExp(searchQueryString, 'gi'),
    (match) => `<span class="highlighted-text">${match}</span>`,
  )
}
const itemBeforeLeave = (element: HTMLElement) => {
  element.style.height = element.clientHeight + 'px'
}
</script>

<template>
  <div
    id="citiesList"
    class="cities-list-container"
    :class="{ 'cities-list-container--active': activeSearch }"
  >
    <transition-group
      name="list"
      tag="div"
      class="cities-list"
      @before-leave="itemBeforeLeave($event as HTMLElement)"
    >
      <div
        v-for="city in formattedCitiesList"
        :key="city.domain_prefix"
        class="cities-list__item"
      >
        <span v-if="city?.firstLetter" class="cities-list__first-letter">
          {{ city.firstLetter }}
        </span>

        <a
          class="cities-list__link"
          :href="city.cityUrl"
          :title="cityTitle + city.visible_name"
          v-html="highlightMatchedLetters(city.visible_name)"
        />
      </div>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">
.cities-list-container {
  height: 0;
  margin-top: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.1s;

  &--active {
    height: 540px;
    margin-top: 10px;
    opacity: 1;

    @include media('<tablet') {
      height: auto;
    }

    :deep(.cities-list) {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

:deep {
  .list-enter-active,
  .list-leave-active {
    transition: all 0.1s ease;
  }

  .list-enter-from,
  .list-leave-to {
    height: 0 !important;
    overflow: hidden;
    opacity: 0 !important;
  }

  .list-enter-to {
    height: 30px !important;
    overflow: hidden;
    opacity: 1 !important;
  }

  .cities-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: max-content;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.15s;
    transition-delay: 0.1s;
    transform: translate(0, 100%);

    @include media('<desktop-mini') {
      width: 100%;
    }

    &__item {
      position: relative;
      width: 170px;
      padding-left: 20px;
      font-size: 14px;
      line-height: 30px;

      &_empty {
        height: 30px;
      }

      .highlighted-text {
        color: var(--ui-primary);
      }

      @include media('<desktop-mini') {
        width: calc(100% / 4);
      }

      @include media('<tablet') {
        width: 100%;
      }
    }

    &__first-letter {
      position: absolute;
      left: 0;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
      color: var(--ui-primary);
    }

    &__link {
      line-height: 30px;
      color: #fff;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
