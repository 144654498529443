import { defineStore } from 'pinia'

/* Types */
import { StoreId } from '@/store'
import type { CityState } from '@/store/cities/cities.types'
import { Success } from '@/types/api/results'
import type { City } from '@/types/api/cities'

export const useCitiesStore = defineStore({
  id: StoreId.CITIES,
  state: (): CityState => ({
    citiesList: [],
  }),
  actions: {
    async fetchCities() {
      const { $apiService } = useNuxtApp()

      const result = await $apiService.cities.fetchCitiesList()

      if (result instanceof Success) {
        this.citiesList = result.data as City[]
        this.citiesList.sort((a: City, b: City) =>
          a.visible_name.localeCompare(b.visible_name, 'ru'),
        )
      }
    },
  },
})
