export interface MenuLink {
  title: string
  url: string
}

export enum DOMAIN_COUNTRY {
  ru = 'Россия',
  kg = 'Кыргызстан',
  com = 'Казахстан',
}

export interface MainPageSEO {
  title: string
  keywords: string
  description: string
}

export interface ImportantLinks {
  title: string
  anchor: string
}
