<script setup lang="ts">
import Photo1 from '@/assets/images/photo-1.webp'
import Photo2 from '@/assets/images/photo-2.webp'
import Photo3 from '@/assets/images/photo-3.webp'
import Photo4 from '@/assets/images/photo-4.webp'
import Photo5 from '@/assets/images/photo-5.webp'
import Photo6 from '@/assets/images/photo-6.webp'

const { isDesktop } = useResponsive()

const listPhotoColumn1 = [Photo1, Photo2, Photo3]
const listPhotoColumn2 = [Photo4, Photo5, Photo6]

const carouselPhotos = computed(() => [
  ...listPhotoColumn1,
  ...listPhotoColumn2,
])
</script>

<template>
  <div id="photoBlock" class="photo-block">
    <h2 class="photo-block__title">Фото</h2>

    <div v-if="isDesktop" class="photo-block__photos-container">
      <div class="photo-block__photos-column photo-block__photos-column--1">
        <img
          v-for="(photo, index) in listPhotoColumn1"
          :key="`column_1_${index}`"
          class="photo-block__photo"
          alt="Фото"
          :src="photo"
          width="582"
          height="331"
        />
      </div>

      <div class="photo-block__photos-column photo-block__photos-column--2">
        <img
          v-for="(photo, index) in listPhotoColumn2"
          :key="`column_2_${index}`"
          class="photo-block__photo"
          alt="Фото"
          :src="photo"
          width="582"
          height="331"
        />
      </div>
    </div>

    <the-slider
      v-else
      is-different-width
      :space-between="10"
      :slide-width="300"
      class="photo-block__carousel"
    >
      <img
        v-for="(photo, index) in carouselPhotos"
        :key="index"
        class="photo-block__carousel-photo"
        :src="photo"
        alt="Фото"
      />
    </the-slider>

    <div class="photo-block__link-text">
      Больше фото в нашей группе
      <a href="https://vk.com/farfordostavka" class="photo-block__link">
        Вконтакте
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.photo-block {
  padding-bottom: 120px;

  &__title {
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 800;
    color: #fff;

    @include media('<desktop-mini') {
      font-size: 30px;
    }
  }

  &__photos-container {
    display: flex;
    gap: 36px;
  }

  &__photos-column {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &--1 {
      padding-bottom: 50px;
    }

    &--2 {
      padding-top: 50px;
    }
  }

  &__photo {
    object-fit: cover;
    border-radius: 20px;

    @include media('<desktop') {
      width: 482px;
      height: 274px;
    }
  }

  &__link-text {
    color: var(--ui-white);
    width: 50%;
    padding-left: 18px;
    margin-top: 40px;
    margin-left: auto;
    font-size: 20px;
    font-weight: 400;
  }

  &__link {
    text-decoration: underline;
    font-weight: 800;
  }

  &__carousel {
    margin-bottom: 56px;

    :deep(.app-slider__track) {
      border-radius: 20px;
    }
  }

  @include media('<desktop-mini') {
    padding: 0 48px 120px 48px;

    &__link-text {
      color: var(--ui-white);
      width: unset;
      padding-left: 0;
      margin-top: 14px;
      font-weight: 400;
      margin-left: unset;
    }

    &__link {
      font-weight: 800;
    }

    &__carousel-photo {
      width: 100%;
      object-fit: contain;
      height: 400px;
    }
  }

  @include media('<tablet') {
    padding: 0 20px 120px 20px;

    &__carousel-photo {
      width: 100%;
      object-fit: fill;
      height: 200px;
    }
  }
}
</style>
