<script setup lang="ts">
import type { PropType } from 'vue'

/* Types */
import type { DailyStatistic } from '@/types/dailyStatistics'

const props = defineProps({
  statistic: {
    type: Object as PropType<DailyStatistic>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const digitValue = ref(0)

watch(
  () => props.statistic.value,
  (newValue) => {
    animate(digitValue.value, newValue, 2000)
  },
)

const animate = (initValue: number, lastValue: number, duration: number) => {
  let startTime = 0

  // pass the current timestamp to the step function
  const step = (currentTime: number) => {
    // if the start time is null, assign the current time to startTime
    if (!startTime) {
      startTime = currentTime
    }

    // calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((currentTime - startTime) / duration, 1)

    // calculate what to be displayed using the value gotten above
    digitValue.value = Math.floor(
      progress * (lastValue - initValue) + initValue,
    )

    // checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) {
      window.requestAnimationFrame(step)
    } else {
      window.cancelAnimationFrame(window.requestAnimationFrame(step))
    }
  }

  // start animating
  window.requestAnimationFrame(step)
}
</script>

<template>
  <div class="statistic-card">
    <div class="statistic-card__oval" :class="`v-oval--${index}`"></div>

    <div class="statistic-card__digits-container">
      <div class="statistic-card__title" v-html="statistic.title"></div>

      <div class="statistic-card__digits">{{ digitValue }}</div>

      <div class="statistic-card__unit">{{ statistic.unit }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.statistic-card {
  &__oval {
    position: absolute;
    width: 54px;
    height: 54px;
    background-color: var(--ui-primary);
    border-radius: 50%;
  }

  .v-oval--0 {
    top: -21px !important;
    // городов
    left: 9px !important;
  }

  .v-oval--1 {
    top: -29px !important;
    // кухонь
    left: -11px !important;
  }

  .v-oval--2 {
    // среднее время доставки
    top: -29px !important;
    left: -11px !important;
  }

  .v-oval--3 {
    top: 0 !important;
    // сегодня приготовили
    left: -11px !important;
  }

  .v-oval--4 {
    top: -9px !important;
    // курьеры км проехали
    left: 19px !important;
  }

  .v-oval--5 {
    top: -20px !important;
    // сотрудников
    left: 29px !important;
  }

  &__digits-container {
    position: relative;
    color: #fff;
  }

  &__digits {
    font-size: 60px;
    font-weight: 800;
    line-height: 30px;
  }

  &__unit {
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
  }

  &__title {
    position: absolute;
    top: -57px;
    left: 52px;
    width: 108px;
    height: 28px;
    font-size: 12px;
    font-weight: 800;
    line-height: 14px;
  }

  @include media('<tablet') {
    &__title {
      top: -66px;
      left: 0;
      width: 159px;
      height: 36px;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
    }

    &__digits {
      font-size: 55px;
    }

    &__unit {
      font-size: 25px;
    }

    .v-oval--0 {
      top: -16px;
      // городов
      left: 23px;
    }

    .v-oval--1 {
      top: -19px;
      // кухонь
      left: -11px;
    }

    .v-oval--2 {
      top: -19px;
      // среднее время доставки
      left: -11px;
    }

    .v-oval--3 {
      top: -16px;
      // сегодня приготовили
      left: -12px;
    }

    .v-oval--4 {
      top: -19px;
      // курьеры км проехали
      left: 39px;
    }

    .v-oval--5 {
      top: -2px;
      // сотрудников
      left: 29px;
    }
  }
}
</style>
