<script setup lang="ts">
/* Components */
import CitiesBlock from '@/components/Main/CitiesBlock/CitiesBlock.vue'
import NewsBlock from '@/components/Main/NewsBlock.vue'
import PhotoBlock from '@/components/Main/PhotoBlock.vue'

/* Types */
import {
  HttpStatusCode,
  NotFoundError,
  ServerError,
  Success,
} from '@/types/api/results'
import { useCitiesStore } from '@/store/cities/cities'

const route = useRoute()
const router = useRouter()

const { $apiService, $host } = useNuxtApp()
const { fetchCities } = useCitiesStore()

const { data: pageCeo, error } = await useAsyncData(async () => {
  const result = await $apiService.common.fetchMainPageCeo()

  if (result instanceof NotFoundError) {
    throw createError({
      statusCode: HttpStatusCode.NOT_FOUND,
      message: result.message || '',
    })
  }

  if (result instanceof ServerError) {
    throw createError({
      statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
      message: result.message || '',
    })
  }

  if (result instanceof Success) {
    return result?.data
  }
})

if (error.value) {
  showError(error.value)
}

const onScrollToBlock = () => {
  const scrollName = route.query?.scrollTo

  if (scrollName) {
    router.replace({ query: {} })

    setTimeout(() => {
      document
        .querySelector<HTMLElement>(`#${scrollName as string}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 200)
  }
}

onMounted(async () => {
  await fetchCities()

  onScrollToBlock()
})

const title =
  pageCeo.value?.title ||
  'Ресторан Farfor — доставка суши, роллов и пиццы более чем в 100 городах России'

useHead({
  title,
  ...SEOMaker(
    $host,
    {
      title,
      keywords: pageCeo.value?.keywords || '',
      description: pageCeo.value?.description || '',
    },
    route.fullPath,
    route.path,
  ),
})
</script>

<template>
  <div class="base-content">
    <Head>
      <Meta name="yandex-verification" content="d07b19776e5595f8" />
    </Head>

    <cities-block />
    <news-block />
    <photo-block />
  </div>
</template>

<style lang="scss">
.base-content {
  margin-top: -90px; // высота хедера

  @include media('<desktop-mini') {
    margin-top: -121px; // убираем высоту хедера, т.к. больше нету видео под хедером
  }

  @include media('<tablet') {
    margin-top: 0; // убираем высоту хедера, т.к. больше нету видео под хедером
  }
}
</style>
